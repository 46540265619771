export const fallbackLng = 'en-GB'
export const languages = [
  fallbackLng,
  'en-US',
  'ar',
  'cs',
  'da',
  'de',
  'es',
  'el',
  'fi',
  'fr',
  'hi',
  'id',
  'it',
  'ja',
  'ko',
  'ms',
  'nl',
  'pl',
  'pt',
  'ro',
  'ru',
  'sk',
  'sl',
  'sv',
  'th',
  'tl',
  'tr',
  'uk',
  'vi',
  'zh',
]

export const defaultNS = 'common'

export function getOptions(
  lng = fallbackLng,
  ns: string | string[] = defaultNS,
) {
  return {
    // debug: true,
    supportedLngs: languages,
    // preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    returnEmptyString: false,
  }
}
